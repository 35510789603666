import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { calcTotal, filterPhone } from "../../Helpers/itemsFilter";
import { sendOrder } from "../../services/fetchingProducts";
import CardPannier from "../../components/CardPannier/CardPannier";
import toast from "react-hot-toast";
import style from "./Checkout.module.css";
import { useDispatch } from "react-redux";
import { cartActions } from "../../redux/cartSlice";
import Order from "../Order/Order";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Helmet } from "react-helmet";
import ImportScript from "../../ImportScript";
// const pattern_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function Checkout() {
  ImportScript(
    "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js"
  );
  ImportScript("assets/js/libs.min.js");
  ImportScript("assets/js/scripts.min.js");
  ImportScript("assets/js/test.js");

  const store = useSelector((state) => state.cartReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  // --------------
  const [nameIsValid, setNameIsValid] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [adressIsValid, setAdressIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  //--------------
  const total = (
    parseFloat(totalPrice.totalPriceAddons) +
    parseFloat(totalPrice.totalPriceProduct)
  ).toFixed(2);
  // -------------
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [adress, setAdress] = useState("");
  const [info, setInfo] = useState("");
  const [order, setOrder] = useState({
    city: "Mohammedia",
    state: "Mohammedia",
    zipcode: 0,
    country: "Maroc",
    merchant_id: 10,
    request_from: "single_web",
    trans_type: "delivery",
    payment_type: "cod",
    delivery_time: "0:0",
    delivery_date: "2022-4-21",
    delivery_asap: 1,
    dinein_special_instruction: "",
    dinein_table_number: "",
    dinein_number_of_guest: "",
    delivery_charge: 0,
    items: store.products,
    delivery_instruction: info || " ",
    email_address: "singlewebpowerbizfr@yopmail.com",
  });

  const handleSelectStartAddress = (address) => {
    setAdress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setOrder({
          ...order,
          google_lng: latLng.lng || 36.732738,
          google_lat: latLng.lat || 3.1458408,
          street: address,
        });
      })
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    setTotalPrice(calcTotal(store.products));
  }, [store]);

  useEffect(() => {
    setOrder({
      ...order,
      sub_total: total,
      total_w_tax: total,
    });
  }, [totalPrice]);

  useEffect(() => {
    setForm();
  }, [nameIsValid, lastNameIsValid, phoneIsValid, adressIsValid]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!nameIsValid) {
      toast("Inseret votre nom", {
        style: {
          background: "#E74C3C",
          color: "#efefef",
        },
      });
    } else if (!lastNameIsValid) {
      toast("Inseret votre prénom", {
        style: {
          background: "#E74C3C",
          color: "#efefef",
        },
      });
    } else if (!phoneIsValid) {
      toast("📱 Il semble que votre numéro de téléphone est incorrect", {
        style: {
          background: "#E74C3C",
          color: "#efefef",
        },
      });
    } else if (!adressIsValid) {
      toast("📌 adresse invalide", {
        style: {
          background: "#E74C3C",
          color: "#efefef",
        },
      });
    }
    sendOrder(order)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw new Error();
      })
      .then((data) => {
        setShowModal(() => {
          return true;
        });
        setOrderId(data.order_id);
        dispatch(cartActions.removeAll());
      })
      .catch(() => {
        toast("Une erreur s'est produite, veuillez actualiser la page!", {
          style: {
            background: "#E74C3C",
            color: "#efefef",
          },
        });
      });
  };
  const nameInputHandler = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        setOrder({
          ...order,
          last_name: e.target.value,
        });
        if (e.target.value.length > 2) {
          setNameIsValid(true);
        } else setNameIsValid(false);
        break;
      case "lastname":
        setLastName(e.target.value);
        setOrder({
          ...order,
          first_name: e.target.value,
        });
        if (e.target.value.length > 2) {
          setLastNameIsValid(true);
        } else setLastNameIsValid(false);
        break;
      case "phone":
        setPhone(e.target.value);
        setOrder({
          ...order,
          contact_phone: filterPhone(e.target.value),
        });
        if (e.target.value.length >= 9 && e.target.value.length <= 10) {
          setPhoneIsValid(true);
        } else setPhoneIsValid(false);
        break;
      case "adress":
        setAdress(e);
        setOrder({
          ...order,
          street: e,
        });
        console.log(e.length);
        if (e.length > 8) {
          setAdressIsValid(true);
        } else setAdressIsValid(false);
        break;
      case "info":
        setOrder({
          ...order,
          delivery_instruction: e.target.value,
        });
        setInfo(e.target.value);
        break;
    }
  };

  const setForm = () => {
    console.log(nameIsValid, lastNameIsValid, phoneIsValid, adressIsValid);
    if (!nameIsValid || !lastNameIsValid || !phoneIsValid || !adressIsValid)
      setFormIsValid(false);
    else setFormIsValid(true);
  };

  const deleteHandler = () => {
    dispatch(cartActions.removeAll());
    navigate("/cart");
    toast("🛒 Votre panier est vide", {
      style: {
        background: "#1f615d",
        color: "#efefef",
      },
    });
  };
  return (
    <div className="container">
      <Helmet>
        <title>Villa Allebra - Pannier</title>
        <meta name="description" content="menu ville-allebra" />
      </Helmet>
      {!showModal ? (
        <div className={style.container}>
          <form className={style.form} onSubmit={onSubmitHandler}>
            <div className={style.inputFlex}>
              {" "}
              <div>
                <label htmlFor="lastname" className={style.label}>
                  Prénom<sup>*</sup>
                </label>
                <input
                  className={style.input}
                  type="text"
                  name="lastname"
                  required
                  onChange={(e) => nameInputHandler(e, "lastname")}
                  value={lastName}
                />
              </div>
              <div>
                <label htmlFor="nom" className={style.label}>
                  Nom<sup>*</sup>
                </label>
                <input
                  className={style.input}
                  type="text"
                  name="nom"
                  required
                  onChange={(e) => nameInputHandler(e, "name")}
                  value={name}
                />
              </div>
            </div>
            <div>
              <label htmlFor="telepone" className={style.label}>
                Téléphone<sup>*</sup>
              </label>
              {phone.length > 10 && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#B20600",
                  }}
                >
                  * Le numéro de téléphone que vous avez saisi semble incorrect.
                  Merci de le modifier avant de valider votre commande.
                </span>
              )}
              <div className={style.phone}>
                <span className={style.region}>+212</span>
                <input
                  className={style.phoneNumber}
                  type="tel"
                  name="telephone"
                  required
                  onChange={(e) => nameInputHandler(e, "phone")}
                  value={phone}
                />
              </div>
            </div>
            <div>
              <PlacesAutocomplete
                value={adress}
                onChange={(e) => {
                  setAdress(e);
                  nameInputHandler(e, "adress");
                }}
                onSelect={(e) => {
                  handleSelectStartAddress(e);
                  nameInputHandler(e, "adress");
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <label htmlFor="nom">
                      Adresse<sup>*</sup>
                    </label>
                    <input
                      className={style.input}
                      type="text"
                      name="adress"
                      required
                      value={adress}
                      {...getInputProps({
                        placeholder: "Tapez votre adresse ici ...",
                        className: style.input,
                        required: true,
                        type: "text",
                        name: "adress",
                      })}
                    />
                    <div
                      className="autocomplete-dropdown"
                      style={{
                        position: "absolute",
                        top: "70px",
                        width: "100%",
                        zIndex: "1000",
                      }}
                    >
                      <div className="autocomplete-dropdown-container rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";

                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              key={suggestion.index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div>
              <label htmlFor="nom" className={style.label}>
                Informations complémentaires
              </label>
              <textarea
                className={style.textArea}
                type="text"
                name="nom"
                onChange={(e) => nameInputHandler(e, "info")}
                value={info}
              />
              {!formIsValid && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#B20600",
                  }}
                >
                  * Vous avez oublié de sélectionner ou de renseigner des champs
                  obligatoires
                </span>
              )}
              <button
                type="submit"
                className={style.button + " text_yanone"}
                style={{ fontSize: "18px" }}
                disabled={!formIsValid}
              >
                Commander
              </button>
            </div>
          </form>
          <div className={style.productsContainer}>
            <div className={style.title}>
              <div>
                <i className="fa-solid fa-basket-shopping"></i>
              </div>
              <div>
                <h3 className="text-right">{total} DH</h3>
              </div>
            </div>
            <div className={style.carts}>
              {store.qty !== 0 &&
                store.products.map((item, i) => (
                  <CardPannier item={item} i={i} key={i} aos={false} />
                ))}
            </div>
            <button
              style={{ fontSize: "18px" }}
              type="button"
              className={style.annuler + " text_yanone"}
              onClick={deleteHandler}
            >
              Vider le panier
            </button>
          </div>
        </div>
      ) : (
        <Order
          orderId={orderId}
          className={style.button}
          closeModal={setShowModal}
        />
      )}
    </div>
  );
}

export default Checkout;
