import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import LargeCard from "../../components/LargeCard/LargeCard";
import RecrutementLoader from "../../components/Skeletons/RecrutementLoader";
import style from "./Recrutement.module.css";
const URL = process.env.REACT_APP_ADMIN_API;

function Recrutement() {
  const [posts, setPosts] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  useEffect(() => {
    axios
      .get(`${URL}recrutements`)
      .then((result) => setPosts(result.data))
      .finally(() => {
        setDataIsLoaded(true);
      });
  }, []);

  return (
    <div className="container mt-10">
      <div className={style.title}>
        <h2>Recrutement</h2>
      </div>
      {dataIsLoaded ? (
        <div>
          {posts?.data &&
            posts?.data.length > 0 &&
            posts.data.map((data, i) => (
              <LargeCard data={data} i={i} key={i} />
            ))}
        </div>
      ) : (
        <div>
          <div className={style["loader-container"]}>
            <RecrutementLoader />
          </div>
          <div className={style["loader-container"] + " mt-5"}>
            <RecrutementLoader />
          </div>
        </div>
      )}
    </div>
  );
}

export default Recrutement;
