import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ButtonPrimaryFadeIn from "../Buttons/ButtonPrimaryFadeIn";
import CustomLink from "./CustomLink";
import style from "./NavBar.module.css";

function DiverNavbar() {
  const [isShown, setIsShown] = useState(true);
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);
  const controlNavbar = () => {
    if (window.scrollY < 300) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  function detectSize() {
    setWindowDimension(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  function toggle() {
    if (windowDimension > 991) return;
    document.querySelector(".menu").classList.toggle("menu_state_open");
    document.querySelector("body").classList.toggle("modal_open");
  }

  return (
    <>
      <div
        className={`l_m_global headroom ${isShown && style.scrolled}`}
        style={{
          background: "rgba(0,0,0, 0.8)",
          height: "100px",
        }}
      >
        <div className="container l_m" style={{ height: "100px" }}>
          <CustomLink
            to={"/"}
            className="logo"
            reload={() => window.location.reload()}
          >
            <img
              src="assets/img/logo.png"
              alt="villa-allebra"
              className="logo-villa"
            />
          </CustomLink>
          <span className="fas fa-bars" id="menu_icon"></span>
          <nav className="menu">
            <CustomLink
              to={"/events"}
              reload={() => window.location.reload()}
              toggle={toggle}
            >
              Events
            </CustomLink>
            <CustomLink to={"/jobs"} toggle={toggle}>
              Recrutement
            </CustomLink>
            <CustomLink to={"/employee"} toggle={toggle}>
              L'employé du mois
            </CustomLink>
          </nav>
        </div>
      </div>
    </>
  );
}
export default DiverNavbar;
