import React from 'react';

const SOCIAL = [
  {
    icon: 'fab fa-facebook-f',
    url: 'https://www.facebook.com/VillaAllebraa/',
  },
  {
    icon: 'fab fa-instagram',
    url: 'https://www.instagram.com/villaallebra/',
  },
];

function Social() {
  return (
    <div className='icon_social'>
      {/* {SOCIAL.map((item, i) => (
        <a href={item.url} key={i} target="_blank">
          <i className={item.icon}></i>
        </a>
      ))} */}
    </div>
  );
}

export default Social;
