import React from "react";
import Header from "../../components/Header/Header";
import Gallery from "../../containers/Gallery/Gallery";
import ImportScript from "../../ImportScript";

function About() {
  ImportScript(
    "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js"
  );
  ImportScript("assets/js/libs.min.js");
  ImportScript("assets/js/scripts.min.js");
  ImportScript("assets/js/test.js");
  return (
    <div id="about">
      <Header
        item={{
          titre: "A propos",
          description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Necessitatibus at suscipit enim expedita natus eligendi architecto
                rerum asperiores.`,
          imageUrl:
            "https://images.unsplash.com/photo-1488992783499-418eb1f62d08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3689&q=80",
        }}
      />
      <section className="info_contact">
        <div className="container">
          <div className="contact_info">
            <div className="logo_about col-lg-12">
              {/* <img src="assets/img/Logo_about.png" alt="" /> */}
              <p>Villa-Allebra</p>
            </div>
            <h2>About the restaurant</h2>
            <span className="up_i">oraco products</span>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Necessitatibus at suscipit enim expedita natus.
            </p>
          </div>
        </div>
      </section>
      <section className="image_f">
        <div className="container">
          <div className="img_info">
            <div className="col-lg-6 col-12">
              <i className="img_i"></i>
            </div>
            <div className="col-lg-6 col-12">
              <div className="img_fi">
                <span className="up_i">oraco products</span>
                <h2>
                  We <span className="text_color">specialize </span>in modern
                  kitchen.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Reiciendis doloremque deleniti autem! Id reiciendis adipisci
                  deserunt dolore vel dolorem totam magnam blanditiis
                  accusantium illo nesciunt facilis, fugit corrupti.
                </p>
                <a className="button_line" href="single_post.html">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="information_ab">
        <div className="container">
          <div className="col-lg-12">
            <span className="up_i">oraco products</span>
            <h2>Foodrant restaurant</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. <br />{" "}
              Reiciendis doloremque deleniti autem! Id reiciendis adipisci
              deserunt dolore <br /> vel dolorem totam
            </p>
          </div>
        </div>
      </section>
      <section className="reserv_i">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9 col-sm-9 col-12">
              <h2>Lorem ipsum dolor sit Amet, consectetur elit, sed do</h2>
            </div>
            <div className="col-lg-3 col-sm-3 col-12 but_right_align">
              <a className="button_line" href="contacts.html">
                Reservation
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="gallery">
        <div className="container">
          <div className="gallery_block">
            <span className="up_i">from our hearts</span>
            <h2>Gallery</h2>
            <Gallery />
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
