import React from "react";

function ButtonPrimaryFadeOut({
  text = "Click Me!",
  onClick,
  children,
  className,
  type,
}) {
  return (
    <button
      className={"button_fill " + className}
      style={{
        backgroundColor: "#1F615D",
        color: "#fff",
      }}
      onClick={onClick}
      type={!type ? "button" : "submit"}
    >
      {children ? children : text}
    </button>
  );
}

export default ButtonPrimaryFadeOut;
