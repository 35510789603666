import React from "react";

function SearchButton() {
  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className="search_b"
      style={{
        marginTop: "0px",
      }}
      onSubmit={submitHandler}
    >
      <i className="fas fa-times search_icon"></i>
      <input type="search" placeholder="Seacrh" />
    </form>
  );
}

export default SearchButton;
