import React from "react";
import ContentLoader from "react-content-loader";

const RecrutementLoader = (props) => (
  <ContentLoader
    speed={2}
    width={700}
    height={180}
    viewBox="0 0 700 180"
    backgroundColor="#c7c7c7"
    foregroundColor="#cfcfcf"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="200" height="40" />
    <rect x="0" y="60" rx="2" ry="2" width="700" height="20" />
    <rect x="0" y="90" rx="2" ry="2" width="500" height="20" />
    <rect x="0" y="130" rx="2" ry="2" width="150  " height="50" />
  </ContentLoader>
);

export default RecrutementLoader;
