import { useEffect } from "react";
import { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink({ children, to, reload = () => {}, toggle, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li
      style={{
        listStyle: "none",
      }}
      className={match ? "active" : ""}
      onClick={() => {
        reload();
        toggle();
      }}
    >
      <Link
        className="text_playfair"
        to={to}
        {...props}
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Link>
    </li>
  );
}

export default CustomLink;
