import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ImportScript = (url) => {
  const location = useLocation();
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, location.pathname]);
};

export default ImportScript;
