import React from "react";
import style from "./Header.module.css";

function Header({ item }) {
  const { titre, description, imageUrl } = item;
  return (
    <header
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={style.header}
    >
      <div className={style.filter} />
      <div className={`container flex_center ${style.title}`}>
        <div className="title">
          <h1>{titre}</h1>
          <p>{description}</p>
        </div>
      </div>
    </header>
  );
}

export default Header;
