import React from 'react';

function SectionLargeSlider() {
  return (
    <>
      <div className='bg_slider'>
        <div className='swiper-container image-slider'>
          <div className='swiper-wrapper'>
            <div className='swiper-slide'>
              <div
                className='slide-inner'
                style={{
                  background: 'url("assets/img/restaurant/slide1.jpg")',
                }}
              ></div>
            </div>
            <div className='swiper-slide'>
              <div
                className='slide-inner'
                style={{
                  background: 'url("assets/img/restaurant/slide2.jpg")',
                }}
              ></div>
            </div>
            {/* <div className="swiper-slide">
              <div
                className="slide-inner"
                style={{
                  background: 'url("assets/img/restaurant/slide3.jpg")',
                }}
              ></div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='flex_center'>
          <div className='swiper-container quote-slider'>
            <div className='swiper-wrapper'>
              <div className='title swiper-slide'>
                <span className='up_i'>Bienvenue à </span>
                <h1>Villa Allebra</h1>
              </div>
              <div className='title swiper-slide'>
                <span className='up_i'>L'endroit idéal pour votre</span>
                <h1>Pause Café & Repas en famille</h1>
              </div>
              {/* <div className="title swiper-slide">
                <span className="up_i">SUBTITLE</span>
                <h1>
                  Lorem, ipsum. <br /> Lorem
                </h1>
              </div> */}
            </div>
          </div>
        </div>
        <div className='bottom_info'>
          <div className='contact'>
            <div className='i_phone'>
              <i className='icon_phone'></i>
              <div className='info_ph'>
                <span
                  className='text_playfair'
                  style={{
                    fontSize: '18px',
                  }}
                >
                  Téléphone
                </span>
                <p>
                  <a
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    href='tel:+212808561692'
                  >
                    +212 8 08 56 16 92
                  </a>
                </p>
                <p>
                  <a
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    href='tel:+212630100996'
                  >
                    +212 6 30 10 09 96
                  </a>
                </p>
              </div>
            </div>
            <div className='i_adress'>
              <i className='icon_adress'></i>
              <div className='info_a'>
                <span
                  className='text_playfair'
                  style={{
                    fontSize: '18px',
                  }}
                >
                  Adresse
                </span>
                <p>
                  <a
                    rel='noreferrer'
                    target='_blank'
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    href='https://www.google.com/maps/place/Villa+Allebra/@33.7135531,-7.3470444,15z/data=!4m5!3m4!1s0x0:0x49901f64a0cc8621!8m2!3d33.7135315!4d-7.3470276'
                  >
                    22 LA SIESTA BD HASSAN II
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='slider'>
            <div className='slider_namber swiper-pagination'></div>
            <div className='button_sl'>
              <i className='icon_left swiper-button-prev'></i>
              <i className='icon_right swiper-button-next'></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionLargeSlider;
