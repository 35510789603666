import Axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
};

const wrapPromise = (promise) => {
  let status = "pending";
  let response;

  const suspender = promise.then(
    (res) => {
      status = "success";
      response = res;
    },
    (err) => {
      status = "error";
      response = err;
    }
  );

  const read = () => {
    switch (status) {
      case "pending":
        throw suspender;
      case "error":
        throw response;
      default:
        return response;
    }
  };

  return { read };
};

export const getRestauData = () => {
  const promise = Axios({
    method: "GET",
    url: `https://api.powerbiz.fr/website_api/public/restaurants/10`,
    headers,
  });
  return promise;
};

export const getAllProducts = () => {
  const promise = Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}items`,
    headers,
  });
  return wrapPromise(promise);
};

export const getCategories = () => {
  const promise = Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}categories`,
    headers,
  });
  return wrapPromise(promise);
};

export const getProductsByCategory = (cat_id) => {
  const promise = Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}categories/${cat_id}/items`,
    headers,
  });
  return promise;
};

export const getProductsByKeyword = (keyword) => {
  const promise = Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}search/${keyword}?description=false`,
    headers,
  });
  console.log(promise);
  return promise;
};

export const getProductIngredients = (prod_id) => {
  return Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}items/${prod_id}/ingredients`,
    headers,
  }).then((res) => res.data);
};

export const getProductSubCategory = (prod_id) => {
  return Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}items/${prod_id}/sub_categories`,
    headers,
  }).then((res) => res.data);
};

export const getProductSubCategoryItems = (prod_id, subcat_id) => {
  return Axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}items/${prod_id}/sub_categories/${subcat_id}/sub_items`,
    headers,
  }).then((res) => res.data);
};

export const sendOrder = (order) => {
  const promise = fetch(`${process.env.REACT_APP_API}orders/addOrder`, {
    method: "POST",
    headers,
    body: JSON.stringify(order),
  });
  return promise;
};

export const sendForm = (form) => {
  console.log({
    email_form: form.get("email"),
    email_to: "mejjad.el.mehdi@gmail.com",
    name: form.get("name"),
    object: form.get("object"),
    message: form.get("message"),
    telephone: form.get("tel"),
  });
  const promise = fetch(process.env.REACT_APP_API_CONTACT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    },
    body: JSON.stringify({
      email_form: form.get("email"),
      email_to: "mejjad.el.mehdi@gmail.com",
      name: form.get("name"),
      object: form.get("object"),
      message: form.get("message"),
      telephone: form.get("tel"),
    }),
  });

  return promise;
};
