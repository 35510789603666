import React from "react";
import ContentLoader from "react-content-loader";

const EventLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={600}
    viewBox="0 0 400 600"
    backgroundColor="#c7c7c7"
    foregroundColor="#cfcfcf"
    {...props}
  >
    <rect x="0" y="60" rx="2" ry="2" width="400" height="600" />
  </ContentLoader>
);

export default EventLoader;
