import { motion } from "framer-motion";
import React from "react";
import style from "./Modal.module.css";

function Modal({ toggle = () => {}, close = () => {} }) {
  return (
    <>
      <motion.div className={style.backdrop}></motion.div>
      <motion.div
        className={style.modalContainer}
        initial={{
          top: "-100%",
        }}
        animate={{
          top: "20%",
          opacity: 1,
          transition: {
            duration: 1,
            delay: 1,
          },
        }}
      >
        <h2>Notre restaurant est actuellement fermé</h2>
        <p>
          Nous vous informons que notre restaurant est actuellement fermé. Vous
          pouvez tout de même consulter notre carte, mais nous vous invitons à
          passer commande durant nos horaires d'ouverture.
        </p>
        <button
          onClick={() => {
            close();
          }}
          className={style.button}
        >
          C'EST COMPRIS
        </button>
      </motion.div>
    </>
  );
}

export default Modal;
