import { motion } from "framer-motion";
import React from "react";
import style from "./ModalSkeleton.module.css";

const skeletonVariant = {
  fadeInOut: {
    opacity: 0.5,
    transition: {
      repeat: Infinity,
      duration: 1,
      delay: 0.2,
    },
  },
};

function ModalSkeleton() {
  return (
    <motion.div
      variants={skeletonVariant}
      animate="fadeInOut"
      className={style.modaleSkeleton}
    >
      <div
        style={{
          height: "30px",
          width: " 150px",
          backgroundColor: "#888",
        }}
      ></div>
      <div
        style={{
          height: "20px",
          width: " 220px",
          backgroundColor: "#888",
        }}
      ></div>
      <div
        style={{
          height: "20px",
          width: " 200px",
          backgroundColor: "#888",
        }}
      ></div>
    </motion.div>
  );
}

export default ModalSkeleton;
