import React from 'react';
import Social from '../Social/Social';

function CardTeam({ item, index }) {
  return (
    <div className='col-lg-4'>
      <div
        className='info_block'
        data-aos='zoom-in-right'
        data-aos-duration='800'
        data-aos-delay={100 * index}
      >
        <img
          src={item?.attributes?.Photo?.data?.attributes.formats.small.url}
          alt=''
        />
        <h3>{item?.attributes?.NomComplet}</h3>
        <p>{item?.attributes?.Poste}</p>
        <Social />
      </div>
    </div>
  );
}

export default CardTeam;
