import React from "react";
import { memo } from "react";
import style from "./CardMenu.module.css";

function CardMenu({ index, item = "assets/img/food1.jpeg", openCart }) {
  const { item_name, item_description, price, photo } = item;

  function handelAddToCart() {
    openCart(item);
  }

  return (
    <div className={`col-lg-6 ${style.cart}`} onClick={handelAddToCart}>
      <div
        style={{ paddingTop: "10px" }}
        className="img_text_menu"
        data-aos={(index + 1) % 2 !== 0 ? "fade-right" : "fade-left"}
        data-aos-duration="800"
        data-aos-delay={100 * index}
      >
        <img
          src={
            photo
              ? `https://food.powerbiz.fr/upload/${photo}`
              : "assets/img/logo192.png"
          }
          alt="menu_img"
        />
        <div className="info_title col-lg-8 col-sm-10">
          <h3>{item_name}</h3>
          <p className={style.description}>{item_description}</p>
        </div>
        <span>{price[0]} DH</span>
      </div>
    </div>
  );
}

export default memo(CardMenu);
