import React from "react";
import { Link } from "react-router-dom";

function ButtonPrimaryFadeIn({
  text = "Click Me!",
  to = "/",
  className,
  children,
  toggle = () => {},
}) {
  return (
    <Link
      className={`button_line ${className}`}
      style={{ zIndex: "10" }}
      to={to}
      onClick={() => toggle()}
    >
      {children ? children : text}
    </Link>
  );
}

export default ButtonPrimaryFadeIn;
