import React from "react";

function ButtonPrimaryFadeIn({
  text = "Click Me btn!",
  to = "/",
  className,
  children,
  onClick = () => {},
  type = "button",
}) {
  return (
    <a
      type={type}
      className={`button_line ${className}`}
      style={{ zIndex: "10" }}
      onClick={onClick}
    >
      {children ? children : text}
    </a>
  );
}

export default ButtonPrimaryFadeIn;
