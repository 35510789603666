import { useState } from "react";
import { useEffect } from "react";
import CardProduct from "../../components/CardProduct/CardProduct";
import Header from "../../components/Header/Header";
import ImportScript from "../../ImportScript";
import Loading from "../../Pages/Loading/LoadingLogoSecond";
import {
  getCategories,
  getProductsByCategory,
  getProductsByKeyword,
} from "../../services/fetchingProducts";
import style from "./Shop.module.css";
import { Helmet } from "react-helmet";
import Welcome from "../../containers/Welcome/Welcome";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const allCategories = getCategories();

function Shop({ onOpenCart }) {
  ImportScript(
    "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js"
  );
  ImportScript("assets/js/libs.min.js");
  ImportScript("assets/js/scripts.min.js");
  ImportScript("assets/js/test.js");

  const categories = Object.values(allCategories.read().data);
  const [cat_id, setCat_id] = useState(categories[0].cat_id);
  const [products, setProducts] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const store = useSelector((state) => state.restauReducer);

  useEffect(() => {
    setProducts();
    getProductsByCategory(cat_id)
      .then((res) => setProducts(res.data))
      .then(() => setIsLoaded(true));
  }, [cat_id]);

  const onChangeCategoryhandler = (id) => {
    setCat_id(id);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setProducts();
    setIsLoaded(false);
    let formdata = new FormData(e.target);
    const keyword = formdata.get("keyword");
    if (keyword.length < 2) return;
    getProductsByKeyword(keyword)
      .then((res) => setProducts(res.data))
      .then(() => setIsLoaded(true));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const showToast = () => {
    toast("Restaurant Close", {
      style: {
        background: "#E74C3C",
        color: "#efefef",
      },
    });
  };

  return (
    <div id="shop">
      <Helmet>
        <title>Villa Allebra - shop</title>
        <meta name="description" content="menu ville-allebra" />
      </Helmet>
      <Header
        item={{
          imageUrl: "./assets/img/menu1.jpeg",
          titre: "Notre Carte",
          description: ``,
        }}
      />
      <div className={`container content ${style.container}`}>
        <section className={`products_shop ${style.subContainer}`}>
          <div className="container">
            <div className="row flex-wrap">
              {isLoaded && products ? (
                products.length > 0 ? (
                  products.map((item) => (
                    <CardProduct
                      item={item}
                      key={item.item_id}
                      openCart={!store.is_open ? onOpenCart : showToast}
                    />
                  ))
                ) : (
                  <h4 style={{ margin: "10px auto" }}>
                    Aucun produit ne correspond à votre recherche
                  </h4>
                )
              ) : (
                <>
                  <Loading />
                </>
              )}
            </div>
            <div className="nav_pages"></div>
          </div>
        </section>
        <aside className={`${style.aside}`}>
          <div className="col-lg-12">
            <div className="tags item_widget">
              <h3>Catégories</h3>
              {categories && (
                <div className={`tags_links ${style.categoryCart}`}>
                  <div className={style.gridContainer}>
                    {categories.map((item) => {
                      return (
                        <p style={{ margin: "5px" }}>
                          <a
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            className={
                              item.cat_id === cat_id ? "activeCategory" : ""
                            }
                            key={item.cat_id}
                            onClick={() => onChangeCategoryhandler(item.cat_id)}
                          >
                            {item.category_name}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <form
              className={`${style.form} tags item_widget`}
              onSubmit={submitHandler}
            >
              <h3>Recherche</h3>
              <div className={style.searchContainer}>
                <input
                  type="text"
                  name="keyword"
                  className={style.input}
                  placeholder="Tapez votre recherche"
                />
                <button className={style.search} type="submit">
                  <i className={`fa-solid fa-magnifying-glass`}></i>
                </button>
              </div>
            </form>
          </div>
        </aside>
      </div>
      <Welcome />
    </div>
  );
}

export default Shop;
