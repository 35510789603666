import React from "react";

function NotFound() {
  return (
    <div
      style={{
        width: "100vw",
        margin: "150px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontSize: "28px",
        }}
      >
        404
      </p>
      <h4
        style={{
          margin: "20px 40px",
          fontSize: "38px",
          textAlign: "center",
        }}
      >
        Ouups! CE N'EST PAS LA PAGE QUE VOUS CHERCHIEZ
      </h4>
      <img
        src="assets/img/404.svg"
        alt="not found"
        style={{
          height: "50%",
        }}
      />
    </div>
  );
}

export default NotFound;
