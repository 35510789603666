import { createSlice } from "@reduxjs/toolkit";
const items = JSON.parse(localStorage.getItem("cart"));
const initialState = {
  products: items ? items : [],
  qty: items ? items.length : 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.products.push(action.payload);
      state.qty += action.payload.qty;
    },
    removeAll(state) {
      state.products = [];
      state.qty = 0;
      localStorage.setItem("cart", JSON.stringify([]));
    },
    removeFromCart(state, action) {
      state.products = state.products.filter(
        (item) => item.order_item_id !== action.payload.order_id
      );
      state.qty -= action.payload.qty;
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
