import React, { useState } from "react";
import style from "./Input.module.css";

function CheckBoxInputs({ item, onChange }) {
  const { ingredients_id, ingredients_name, ingredients_name_trans } = item;
  const [isChecked, setIsChecked] = useState(true);

  return (
    <label className={style.multi}>
      <input
        type={"checkbox"}
        name={ingredients_id}
        id={ingredients_id}
        value={JSON.stringify({
          ingredients_id,
          ingredients_name,
          ingredients_name_trans,
          isChecked,
        })}
        onChange={(e) => {
          setIsChecked((prev) => !prev);
          onChange(e.target.value);
        }}
      />
      <span style={{ color: "black" }}>{ingredients_name}</span>
    </label>
  );
}

export default CheckBoxInputs;
