import React, { useEffect } from "react";
import "./order.css";

function Order({ orderId }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <div>
      <div className="wrapper">
        <div className="order_container">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <div className="orderId">
            <h4>Commande Confirmée!</h4>
            <br />
            <h4>ID de commande : {orderId}</h4>
          </div>
          <br />
          <span
            style={{
              margin: "20px auto",
            }}
          >
            Nous vous confirmons la bonne réception de votre commande et la
            traiterons dans les délais convenus
          </span>
        </div>
      </div>
    </div>
  );
}

export default Order;
