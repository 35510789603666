import React from 'react';
import CardTeam from '../../components/CardTeam/CardTeam';

function Team({ subTitle, titre, items }) {
  return (
    <div className='container'>
      <div className='our_team'>
        <div className='our_t col-lg-12'>
          <span className='up_i'>{subTitle}</span>
          <h2>{titre}</h2>
          <div className='row'>
            {items.data.map((item, i) => (
              <CardTeam item={item} key={i} index={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
