import React, { Suspense, useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import HomePage from "../Pages/Home/Home";
import ContactPage from "../Pages/Contact/Contact";
import AboutPage from "../Pages/About/About";
import CheckoutPage from "../Pages/Checkout/Checkout";
import ShopPage from "../Pages/Shop/Shop";
import Loading from "../Pages/Loading/LoadingLogo";
import Footer from "../components/Footer/Footer";
import Pannier from "../containers/Pannier/Pannier";
import SearchButton from "../components/SearchButton/SearchButton";
import Modal from "../containers/Modal/Modal";
import StatusModal from "../containers/StatusModal/Modal";
import { AnimatePresence } from "framer-motion";
import { getRestauData } from "../services/fetchingProducts";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { restaurantActions } from "../redux/restauSlice";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import NotFound from "../Pages/404/404";
import Recrutement from "../containers/Recrutement/Recrutement";
import EmployeeBlog from "../containers/EmployeeBlog/EmployeeBlog";
import Events from "../containers/Events/Events";
import DiverNavbar from "../components/NavBar/DiverNavbar";
import ContactV1 from "../Pages/Contact/ContactV1";

const notify = () =>
  toast("☕️ Produit ajouter au panier", {
    style: {
      background: "#1f615d",
      // #1f615d#E74C3C
      color: "#efefef",
    },
  });

function Index() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.restauReducer);
  const [pannierIsShown, setPannierIsShown] = useState(false);
  const [cartIsShown, setCartIsShown] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [item, setItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    getRestauData()
      .then((res) => dispatch(restaurantActions.setRestaurant(res.data)))
      .then(() => setIsLoading(false));
  }, [dispatch]);

  const togglePannier = () => {
    setPannierIsShown((prev) => !prev);
    toggleScroll();
  };

  const openCart = (item) => {
    toggleScroll();
    setItem(item);
    setCartIsShown(true);
  };

  const toggleScroll = () => {
    document.querySelector("body").classList.toggle("modal_open");
  };

  const closeCart = () => {
    setCartIsShown((prev) => !prev);
    toggleScroll();
  };

  const showToast = () => {
    notify();
  };

  const pathname = window.location.pathname;
  const containDiver =
    pathname.includes("jobs") ||
    pathname.includes("employee") ||
    pathname.includes("events");

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Toaster />
        <SearchButton />
        {pannierIsShown && <Pannier onCloseModal={togglePannier} />}
        {cartIsShown && (
          <Modal item={item} onClose={closeCart} toast={showToast} />
        )}
        {!isLoading && !isClosed && !store.restaurant.is_open && (
          <StatusModal toggle={toggleScroll} close={() => setIsClosed(true)} />
        )}
        {!containDiver ? (
          <NavBar onShowModal={togglePannier} />
        ) : (
          <DiverNavbar />
        )}
        <Suspense fallback={<Loading />}>
          <AnimatePresence>
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={<HomePage onOpenCart={(item) => openCart(item)} />}
              />
              <Route
                path="/cart"
                element={<ShopPage onOpenCart={(item) => openCart(item)} />}
              />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/contact-allebra" element={<ContactV1 />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route index path="/jobs" element={<Recrutement />} />
              <Route path="/employee" element={<EmployeeBlog />} />
              <Route path="/events" element={<Events />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </Suspense>
        <Footer />
      </div>
      {!isLoading && !store.restaurant === {} && store.restaurant.is_active && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: 100000,
          }}
        >
          <Helmet>
            <title>Villa Allebra - Restaurant fermé</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="assets/img/villaAllebra.png"
              alt="vella-allebra"
              style={{
                maxWidth: "60%",
                margin: "auto",
              }}
            />
            <h4>Notre Restaurant est fermé</h4>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
