import React from 'react';
import Social from '../Social/Social';
import style from './Footer.module.css';

function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='footer_contact col-lg-4'>
            <h3 className='text_color'>Adresse</h3>
            <p>
              <a
                rel='noreferrer'
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                target='_blank'
                href='https://www.google.com/maps/place/Villa+Allebra/@33.7135531,-7.3470444,15z/data=!4m5!3m4!1s0x0:0x49901f64a0cc8621!8m2!3d33.7135315!4d-7.3470276'
              >
                22 LA SIESTA BD HASSAN II
              </a>
            </p>
          </div>
          <div className='footer_contact col-lg-4'>
            <h3 className='text_color'>Réservations</h3>
            <p>
              <a className={style.links} href='tel:+212 8 08 56 16 92'>
                08 08 56 16 92
              </a>
            </p>

            <p>
              <a className={style.links} href='tel:+212 8 08 56 16 92'>
                06 30 10 09 96
              </a>
            </p>

            <p>
              <a
                className={style.links}
                href='mailto:villaallebra@gmail.com
'
              >
                villaallebra@gmail.com
              </a>
            </p>
          </div>
          <div className='footer_contact col-lg-4'>
            <h3 className='text_color'>Horaires d'ouverture</h3>
            <p>
              Lundi - Vendredi: 08:00 - 23:30 <br /> Samedi - Dimanche: 08:00 -
              00:30
            </p>
          </div>
        </div>
        <div className='footer_icon'>
          <Social />
          <p>
            © Villa Allebra -2022. Site web réalisé par{' '}
            <a href='https://powerbiz.fr' target='_black'>
              <img
                src='assets/img/logod.png'
                alt='powerBiz'
                style={{ height: '20px', backgroundColor: 'black' }}
              />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
