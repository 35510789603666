import style from "./Input.module.css";
import React, { useState } from "react";

function CheckBoxInputsSubCat({
  subCat_id,
  sub_item,
  getPrice,
  addon_category,
}) {
  const { sub_item_name, multiple_options, sub_item_id, price } = sub_item;
  const [isChecked, setIsChecked] = useState(true);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <label className={multiple_options ? style.multi : style.single}>
        <input
          type={multiple_options ? "checkbox" : "radio"}
          name={subCat_id}
          id={sub_item_id + subCat_id}
          value={JSON.stringify({
            isChecked,
            subcat_id: subCat_id,
            sub_item_id,
            addon_name: sub_item_name,
            addon_category,
            addon_price: price,
            addon_qty: 1,
            multi: multiple_options === "checkbox" ? true : false,
          })}
          onChange={(e) => {
            setIsChecked((prev) => !prev);
            getPrice(e);
          }}
        />
        <span style={{ color: "black" }}>{sub_item_name}</span>
      </label>
      <h4>{price > 0 ? `${price} DH` : "0 DH"} </h4>
    </div>
  );
}

export default CheckBoxInputsSubCat;
