import axios from "axios";
import React, { useEffect, useState } from "react";
import CardEvent from "../../components/CardEvent/CardEvent";
import style from "./Events.module.css";
import EventLoader from "../../components/Skeletons/EventLoader";

function Events() {
  const [eventsData, setEventsData] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}events?populate=*`)
      .then((result) => setEventsData(result.data))
      .finally(() => setIsDataLoaded(true));
  }, []);

  return (
    <>
      <div className="mt-10"></div>

      <h2 className="container">Events</h2>
      {isDataLoaded ? (
        <div className={`${style["grid-container"]} container mt-5`}>
          {eventsData &&
            eventsData?.data &&
            eventsData?.data.map((event, i) => (
              <CardEvent data={event} key={i} />
            ))}
        </div>
      ) : (
        <div className={`${style["grid-container"]} container mt-5`}>
          <EventLoader />
          <EventLoader />
        </div>
      )}
    </>
  );
}

export default Events;
