import React from "react";
import style from "./LargeCard.module.css";
import ButtonPrimaryFadeIn from "../Buttons/ButtonPrimaryFadeIn";
import moment from "moment";
const parse = require("html-react-parser");

function LargeCard({ data, i }) {
  const { publishedAt, description, nom_du_post } = data.attributes;
  return (
    <div
      className={`${style["large-cart"]} mt-5`}
      data-aos="fade-right"
      data-aos-duration="800"
      data-aos-delay={100 * i}
    >
      <h1>{nom_du_post}</h1>
      <div className={`${style["text-container"]}`}>{parse(description)}</div>
      <div className={`${style["cart-footer"]}`}>
        <ButtonPrimaryFadeIn
          text="Postuler"
          to="/contact-allebra"
          className={"mt-2"}
        />
        <div className={style.date}>
          {moment(publishedAt).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
}

export default LargeCard;
