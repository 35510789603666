import React from "react";

function BgGray() {
  return (
    <div
      className="bg_team_block jarallax"
      style={{
        background: 'url("./assets/img/teamvilla.jpeg")',
      }}
    ></div>
  );
}

export default BgGray;
