import React, { useRef, useState } from "react";
import style from "./CardEvent.module.css";
import ReactPlayer from "react-player";
import HTMLReactParser from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
// import "swiper/css/navigation";
import "swiper/css/thumbs";

function CardEvent({ data }) {
  const { Description, Titre, updatedAt, lien_video, Photo } = data.attributes;
  const [isHover, setIsHover] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  // SwiperCore.use([Autoplay, Navigation]);
  return (
    <div className={style["cart-container"]}>
      {Photo && Photo?.data && (
        <>
          <Swiper
            modules={[Autoplay, Navigation, Thumbs]}
            autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {Photo?.data?.map((data, i) => (
              <SwiperSlide key={i}>
                <img
                  src={data?.attributes.formats.small.url}
                  className={style.image}
                />
              </SwiperSlide>
            ))}
            <div className={style["next-prev"]}>
              <button className={style.prev} ref={prevRef}>
                <i class="fa-solid fa-caret-left"></i>
              </button>
              <button className={style.next} ref={nextRef}>
                <i class="fa-solid fa-caret-right"></i>
              </button>
            </div>
          </Swiper>
        </>
      )}
      {!Photo?.data && lien_video && (
        <div className={style["react-player_container"]}>
          <ReactPlayer
            url={lien_video}
            className={style["react-player"]}
            width="100%"
            height="700px"
          />
        </div>
      )}

      {Description && (
        <div
          className={`${style["lire"]}`}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <i
            className={`fa-brands fa-readme ${style.icone} ${
              isHover && style["d-hidden"]
            }`}
          ></i>

          <p className={style.description}>{HTMLReactParser(Description)}</p>
        </div>
      )}
      <div className={style["titre-container"]}>
        <h2 className={style.titre}>{Titre}</h2>
      </div>
    </div>
  );
}

export default CardEvent;
