import React, { useEffect, useState, memo } from "react";
import style from "../../containers/Modal/Modal.module.css";
import { getProductSubCategoryItems } from "../../services/fetchingProducts";
import CheckBoxInputsSubCat from "../Inputs/CheckBoxInputsSubCat";
import ModalSkeleton from "../Skeletons/ModalSkeleton";

function SubCategory({ subCat, item_id, getTotal }) {
  const { subcat_id, subcategory_name } = subCat;

  const createSubCategoryItems = (item) => {
    const jsonItem = JSON.parse(item.target.value);
    getTotal(jsonItem);
  };

  useEffect(() => {
    setIsLoaded(false);
    getProductSubCategoryItems(item_id, subcat_id).then((data) => {
      setSubCatItems(data);
      setIsLoaded(true);
    });
  }, [item_id, subcat_id]);

  const [subCatItems, setSubCatItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {isLoaded ? (
        <div className={style.modalContainer} style={{ margin: "10px 20px" }}>
          <h2 style={{ marginBottom: "10px" }}>{subcategory_name}</h2>
          <div className={style.subItemContainer}>
            {subCatItems.map((sub_item, i) => (
              <CheckBoxInputsSubCat
                sub_item={sub_item}
                key={i}
                subCat_id={subcat_id}
                addon_category={subcategory_name}
                getPrice={createSubCategoryItems}
              />
            ))}
          </div>
        </div>
      ) : (
        <ModalSkeleton />
      )}
    </>
  );
}

export default memo(SubCategory);
