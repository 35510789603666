import { motion } from "framer-motion";
import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import style from "./Modal.module.css";
import {
  getProductIngredients,
  getProductSubCategory,
} from "../../services/fetchingProducts";
import { useState } from "react";
import CheckBoxInputs from "../../components/Inputs/CheckBoxInputs";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import SubCategory from "../../components/SubCategory/SubCategory";
import ModalSkeleton from "../../components/Skeletons/ModalSkeleton";
import { checkIfContansSameCat_id } from "../../Helpers/itemsFilter";

const imageUrl = "https://food.powerbiz.fr/upload/";

const BackDrop = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={style.overlay}
      onClick={onClose}
    ></motion.div>
  );
};

const ModalOverlay = ({ children }) => {
  return (
    <motion.form
      initial={{
        height: 0,
        opacity: 0,
        x: "-50%",
        y: "-40%",
        transition: { when: "afterChildren" },
      }}
      animate={{
        height: "70%",
        x: "-50%",
        y: "-50%",
        opacity: 1,
      }}
      transition={{ type: "spring", duration: 1, bounce: 0.3 }}
      className={style.modal}
    >
      {children}
    </motion.form>
  );
};

function Modal({ item, onClose, toast }) {
  const { item_id, item_name, photo, price, category } = item;
  const [ingredients, setIngredients] = useState();
  const [subCategory, setSubCategory] = useState();
  const [counter, setCounter] = useState(1);
  const [subItemsTotal, setSubItemsTotal] = useState([]);
  const [ingredientItems, setIngredientsItems] = useState([]);

  const product = {
    item_id,
    item_name,
    photo,
    order_item_id: parseInt(item_id) + Math.floor(Math.random() * 1000),
    price: price[0],
    qty: counter,
    cat_id: category[0],
    addon: subItemsTotal,
    order_notes: "",
    normal_price: price[0],
    discounted_price: price[0],
    size: 0,
    size_id: 0,
    ingredients: ingredientItems,
    count: 1,
  };

  const increHandler = () => {
    setCounter((prev) => prev + 1);
  };

  const dicreHandler = () => {
    if (counter === 1) return;
    setCounter((prev) => prev - 1);
  };

  const getSubcatData = (totalPriceSubitems) => {
    const jsonItem = {
      ...totalPriceSubitems,
      addon_price: !totalPriceSubitems.addon_price
        ? 0
        : totalPriceSubitems.addon_price,
    };
    jsonItem.multi
      ? multiOption(jsonItem)
      : singleOption(jsonItem, totalPriceSubitems);
  };

  const singleOption = (jsonItem, totalPriceSubitems) => {
    const CatId = checkIfContansSameCat_id(subItemsTotal, totalPriceSubitems);
    console.log(CatId);
    if (!CatId) {
      setSubItemsTotal((prev) => [...prev, jsonItem]);
    } else {
      const old_subItem = subItemsTotal.findIndex((item) => item.subcat_id);
      console.log(old_subItem);
      subItemsTotal[old_subItem] = jsonItem;
      const newItemPrice = subItemsTotal;
      setSubItemsTotal(() => {
        return newItemPrice;
      });
    }
  };

  const multiOption = (jsonItem) => {
    if (jsonItem.isChecked) {
      setSubItemsTotal((prev) => [...prev, jsonItem]);
    } else {
      const jsonItemIndex = subItemsTotal.findIndex(
        (item) => item.sub_item_id === jsonItem.sub_item_id
      );
      const newItemPrice = subItemsTotal.filter(
        (subItem) => subItem !== subItemsTotal[jsonItemIndex]
      );
      setSubItemsTotal(newItemPrice);
    }
  };

  const multiOptionIngre = (jsonItem) => {
    const item = JSON.parse(jsonItem);
    if (item.isChecked) {
      setIngredientsItems((prev) => [...prev, item]);
    } else {
      const jsonItemIndex = ingredientItems.findIndex(
        (item) => item.sub_item_id === item.sub_item_id
      );
      const newItemPrice = ingredientItems.filter(
        (subItem) => subItem !== ingredientItems[jsonItemIndex]
      );
      setIngredientsItems(newItemPrice);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const [ingre, subcat] = await Promise.all([
        getProductIngredients(item.item_id),
        getProductSubCategory(item.item_id),
      ]);
      setIngredients(ingre);
      setSubCategory(subcat);
    };
    getData();
  }, [item]);

  return (
    <div className={style.modalContainer}>
      {ReactDOM.createPortal(
        <BackDrop onCloseModal={""} onClose={onClose} />,
        document.getElementById("modal")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>
          <ModalHeader prod={item} subItem={subItemsTotal} counter={counter} />
          <div className={style.container}>
            {ingredients ? (
              ingredients.length > 0 && (
                <div
                  className={style.modalContainer}
                  style={{ margin: "20px 20px" }}
                >
                  <h2 style={{ marginBottom: "10px" }}>Ingredients</h2>
                  <div className={style.ingredientContainer}>
                    {ingredients.map((ingredient, i) => (
                      <CheckBoxInputs
                        item={ingredient}
                        key={i}
                        onChange={multiOptionIngre}
                      />
                    ))}
                  </div>
                </div>
              )
            ) : (
              <ModalSkeleton />
            )}

            {subCategory &&
              subCategory.length > 0 &&
              subCategory.map((subCat, i) => (
                <SubCategory
                  key={i}
                  subCat={subCat}
                  item_id={item.item_id}
                  autoId={subCat}
                  getTotal={getSubcatData}
                />
              ))}

            {subCategory &&
              ingredients &&
              subCategory.length === 0 &&
              ingredients.length === 0 && (
                <img
                  src={
                    item.photo
                      ? `${imageUrl}${item.photo}`
                      : "assets/img/logo192.png"
                  }
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    objectFit: "cover",
                  }}
                  width={item.photo ? "100%" : "150px"}
                  height={item.photo ? "60%" : "150px"}
                />
              )}
          </div>

          <ModalFooter
            onClose={onClose}
            increment={increHandler}
            dicrement={dicreHandler}
            counter={counter}
            product={product}
            toast={toast}
          />
        </ModalOverlay>,
        document.getElementById("modal")
      )}
    </div>
  );
}

export default Modal;
