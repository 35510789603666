import _ from "lodash";

export const checkIfContansSameCat_id = (items, jsonItem) => {
  const containCategory = items.some((item) => {
    return item.subcat_id === jsonItem.subcat_id;
  });
  return containCategory;
};

const calcAddons = (addons) => {
  let total = 0;
  if (addons.length > 0) {
    addons.forEach((addon) => {
      total += parseFloat(addon.addon_price);
    });
  }
  return total;
};

export const calcTotal = (items) => {
  let totalPriceProduct = 0;
  let totalPriceAddons = 0;
  items.forEach((item) => {
    totalPriceProduct += parseFloat(item.price) * item.qty;
    totalPriceAddons += calcAddons(item.addon) * item.qty;
  });
  return { totalPriceProduct, totalPriceAddons };
};

export const filterPhone = (phoneNumber) => {
  const startWithZero = parseInt(phoneNumber[0]) === 0;
  if (startWithZero && phoneNumber.length >= 10) {
    return `+212${_.join(_.takeRight([...phoneNumber], 9), "")}`;
  } else return `+212${phoneNumber}`;
};
