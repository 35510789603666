import axios from "axios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import style from "./EmployeeBlog.module.css";
const parse = require("html-react-parser");

const URL = process.env.REACT_APP_ADMIN_API;

function EmployeeBlog() {
  const [employe, setEmploye] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    axios
      .get(`${URL}employees?populate=*`)
      .then((result) => setEmploye(result.data))
      .then(setIsLoaded(true))
      .catch(() => {
        setIsLoaded(true);
        setIsError(true);
      });
  }, []);

  return (
    <>
      <div className={`${style["pd-10"]} `}>
        <h2 className={style.title + " container"}>L'employé du mois</h2>
      </div>

      {employe &&
        employe?.data &&
        employe?.data?.map((data, i) => (
          <div
            className={
              (i + 1) % 2 !== 0
                ? style["article-container"]
                : style["article-container2"]
            }
          >
            {(i + 1) % 2 !== 0 ? (
              <article className="container">
                <div className={style.container}>
                  <div className={style["image-container"]}>
                    <img
                      src={
                        data?.attributes?.Photo?.data?.attributes.formats.small
                          .url
                      }
                      alt=""
                      className={`mt-5 ${style.image}`}
                      width={450}
                    />
                    <img
                      src={require("../../Assets/images/coffe.jpg")}
                      alt=""
                      className={`mt-5 ${style.imagebg}`}
                      width={450}
                    />
                    <h3 className={style.post}>{data?.attributes?.Poste}</h3>
                    <h3 className={style.name}>
                      {data?.attributes?.Nom_complet}
                    </h3>
                  </div>

                  <section className={style.description}>
                    {parse(data?.attributes?.Description)}
                    <p className={style.date}>
                      {moment(data?.attributes?.publishedAt).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </section>
                </div>
              </article>
            ) : (
              <article className="container">
                <div
                  className={
                    (i + 1) % 2 !== 0 ? style["container"] : style["container2"]
                  }
                >
                  <section className={style.description}>
                    {parse(data?.attributes?.Description)}
                    <p className={style.date}>
                      {moment(data?.attributes?.publishedAt).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </section>
                  <div className={style["image-container"]}>
                    <img
                      src={
                        data?.attributes?.Photo?.data?.attributes.formats.small
                          .url
                      }
                      alt=""
                      className={`mt-5 ${style.image}`}
                      width={450}
                    />
                    <img
                      src={require("../../Assets/images/coffe.jpg")}
                      alt=""
                      className={`mt-5 ${style.imagebg}`}
                      width={450}
                    />
                    <h3 className={style.post}>{data?.attributes?.Poste}</h3>
                    <h3 className={style.name}>
                      {data?.attributes?.Nom_complet}
                    </h3>
                  </div>
                </div>
              </article>
            )}
          </div>
        ))}
      {!isLoaded && <h2>LOADING</h2>}
    </>
  );
}

export default EmployeeBlog;
