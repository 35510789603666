import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  restaurant: {},
};

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    setRestaurant(state, action) {
      state.restaurant = action.payload;
    },
  },
});

export const restaurantActions = restaurantSlice.actions;
export default restaurantSlice.reducer;
