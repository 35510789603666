import React from "react";

function Welcome({
  subTitle,
  coloresTitle,
  description,
  normalTitle,
  children,
  imgLink,
}) {
  return (
    <section className="visit_us">
      <div className="visit col-lg-7">
        <span className="up_i">{subTitle}</span>
        <h2>
          {coloresTitle && <span className="text_color">{coloresTitle} </span>}
          {normalTitle && <h2>{normalTitle} </h2>}
          {imgLink && <h2>VELLA ALLEBRA</h2>}
        </h2>
        <p>{description}</p>
        {children}
      </div>
    </section>
  );
}

export default Welcome;
