import React from "react";
import style from "./Gallery.module.css";

function Gallery() {
  return (
    <div className="gallery_img">
      <div className={`col-lg-4 ${style.imageContainer}`}>
        <a data-fancybox="gallery" href="assets/img/restaurant/slide1.jpg">
          <img
            src="assets/img/restaurant/slide1.jpg"
            className={style.image}
            alt="villa-allebra"
          />
        </a>
      </div>
      <div className="col-lg-4">
        <a data-fancybox="gallery" href="assets/img/restaurant/slide2.jpg">
          <img
            src="assets/img/restaurant/slide2.jpg"
            className={style.image}
            alt="villa-allebra"
          />
        </a>
      </div>
      <div className="col-lg-4">
        <a data-fancybox="gallery" href="assets/img/restaurant/slide3.jpg">
          <img
            src="assets/img/restaurant/slide3.jpg"
            className={style.image}
            alt="villa-allebra"
          />
        </a>
      </div>
    </div>
  );
}

export default Gallery;
