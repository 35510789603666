import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CardPannier from "../../components/CardPannier/CardPannier";
import style from "./Pannier.module.css";

const BackDrop = ({ onCloseModal }) => {
  return <div className={style.backDrop} onClick={onCloseModal}></div>;
};

const ModalOverlay = ({ children }) => {
  return <>{children}</>;
};

function Pannier({ onCloseModal }) {
  const store = useSelector((state) => state.cartReducer);
  console.log(store);
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <BackDrop onCloseModal={onCloseModal} />,
        document.getElementById("overlays")
      )}
      {ReactDOM.createPortal(
        <AnimatePresence>
          <ModalOverlay>
            <motion.div
              key="modal"
              className={`${style.pannier}`}
              initial={{ x: "300px" }}
              animate={{ x: 0 }}
              transition={{ type: "tween", duration: 0.4, bounce: 0.3 }}
            >
              <button className={style.closeButton} onClick={onCloseModal}>
                X
              </button>
              <div className={`${style.cardContainer}`}>
                {store.qty !== 0 &&
                  store.products.map((item, i) => (
                    <CardPannier item={item} i={i} key={i} />
                  ))}
              </div>
              {store.qty !== 0 && (
                <Link
                  className="text_yanone"
                  to="/checkout"
                  onClick={onCloseModal}
                  style={{
                    cursor: "pointer",
                    color: "#efefef",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: "bold",
                    backgroundColor: "#1F615D",
                    border: "none",
                    borderRadius: "5px",
                    position: "absolute",
                    bottom: "50px",
                    right: "20px",
                    padding: "15px 30px",
                  }}
                >
                  Valider
                </Link>
              )}
            </motion.div>
          </ModalOverlay>
        </AnimatePresence>,
        document.getElementById("overlays")
      )}
    </React.Fragment>
  );
}

export default Pannier;
