import React from 'react';
import 'aos/dist/aos.css';
import { MENU_DATA, TEAM_DATA } from '../../data/data';
import SmallMenu from '../../containers/SmallMenu/SmallMenu';
import Welcome from '../../containers/Welcome/Welcome';
import BgGray from '../../components/BgGray/BgGray';
import Team from '../../containers/Team/Team';
import SectionLargeSlider from '../../containers/SectionLargeSlider/SectionLargeSlider';
import ImportScript from '../../ImportScript';
import ButtonPrimaryFadeIn from '../../components/Buttons/ButtonPrimaryFadeIn';
import { Helmet } from 'react-helmet';
import { getAllProducts } from '../../services/fetchingProducts';
import _ from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';

const allProducts = getAllProducts();

function Home({ onOpenCart }) {
  ImportScript(
    'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js'
  );
  ImportScript('assets/js/libs.min.js');
  ImportScript('assets/js/scripts.min.js');
  ImportScript('assets/js/test.js');
  const store = useSelector((state) => state.restauReducer);
  const products = Object.values(allProducts.read().data);

  const [randomProducts, setRandomProducts] = useState();
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    setRandomProducts(_.sampleSize(products, 4));
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_ADMIN_API}equipes?populate=*`,
    }).then((res) => {
      setTeamData(res.data);
      console.log(res.data);
    });
  }, []);

  const showToast = () => {
    toast('Restaurant Close', {
      style: {
        background: '#E74C3C',
        color: '#efefef',
      },
    });
  };

  return (
    <div className='home_container'>
      <Helmet>
        <title>Villa Allebra - Home</title>
        <meta name='description' content='menu ville-allebra' />
      </Helmet>
      <header className='home'>
        <SectionLargeSlider />
      </header>
      <section className='service_b'>
        <div className='container'>
          <div className='title_t'>
            <div className='row'>
              <div className='col-lg-4'>
                <span className='up_i'>Pourquoi nous choisir?</span>
              </div>
              <div className='col-lg-6'>
                <h2 style={{ color: 'rgba(0,0,0,0.7)' }}>
                  Tout a été sélectionné avec
                  <span className='text_color'> soin </span>
                </h2>
              </div>
            </div>
          </div>
          <div className='services col-lg-12'>
            <div className='row justify-content-between'>
              <div className='col-lg-4 col-sm-4'>
                <div
                  className='services_item'
                  data-aos='fade-up'
                  data-aos-duration='1000'
                  data-aos-delay='200'
                >
                  <svg
                    className='icon_s'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                  >
                    <use xlinkHref='assets/img/icons/sprite.svg#icon_01_s'></use>
                  </svg>
                  <h3>Lieu</h3>
                  <p className='text-justify'>
                    Après le stress d'une longue journée, rien n'est plus
                    favorable que de passer un moment agréable dans une villa
                    restaurant
                  </p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-4'>
                <div
                  className='services_item'
                  data-aos='fade-up'
                  data-aos-duration='1000'
                  data-aos-delay='300'
                >
                  <svg
                    className='icon_s'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                  >
                    <use xlinkHref='assets/img/icons/sprite.svg#icon_02_s'></use>
                  </svg>
                  <h3>Cuisine</h3>
                  <p className='text-justify'>
                    Notre équipe est choisie avec soin, du manager au serveur
                  </p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-4'>
                <div
                  className='services_item'
                  data-aos='fade-up'
                  data-aos-duration='1000'
                  data-aos-delay='400'
                >
                  <svg
                    className='icon_s'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                  >
                    <use xlinkHref='assets/img/icons/sprite.svg#icon_03_s'></use>
                  </svg>
                  <h3>Service</h3>
                  <p className='text-justify'>
                    En plus d'un lieu agréable et un service confortable, nous
                    vous garantissons une réstauration de qualité
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='image_f img_inf_n'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-6'>
            <div className='bg_inf_p bg_products'></div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='img_info'>
              <div className='img_fi'>
                <h2 style={{ color: 'rgba(0,0,0,0.7)' }}>
                  Nous sommes <span className='text_color'>spécialisés </span>
                  dans la réstauration moderne.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='awards_block jarallax'
        style={{
          backgroundImage: "url('assets/img/im-1.jpeg')",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='awards_inf col-lg-4'>
              <h2>Notes & Avis</h2>
              <p>
                Notre Café Restaurant est très bien noté sur les différentes
                plateformes
              </p>
            </div>
            <div className='awards_img col-lg-8'>
              <a href='https://www.tripadvisor.fr/Restaurant_Review-g2095768-d23584245-Reviews-Villa_Allebra-Mohammedia_Casablanca_Settat.html'>
                {' '}
                <img src='assets/img/award_01.png' loading='lazy' />
              </a>{' '}
              <a href='https://goo.gl/maps/WMGdDwfYmjnBD98Z9'>
                {' '}
                <img src='assets/img/award_02.png' loading='lazy' />
              </a>{' '}
              <a href='https://www.facebook.com/VillaAllebraa'>
                {' '}
                <img src='assets/img/award_03.png' loading='lazy' />
              </a>{' '}
            </div>
          </div>
        </div>
      </section>
      <section className='menu_text_two'>
        {randomProducts && (
          <SmallMenu
            titre={'Quelques propositions'}
            items={randomProducts}
            openCart={!store.is_open ? onOpenCart : showToast}
          />
        )}
      </section>
      {/* Visite Us */}
      {/* <Welcome
        description={'Ensemble pour le meilleur'}
        imgLink='assets/img/team.png'
      /> */}
      {teamData && <section className='team'>
        <BgGray />
        <Team items={teamData} titre={'Notre équipe'} />
      </section>}
      <div style={{ marginTop: '64px' }}></div>
      <Welcome normalTitle={"Nos horaires d'ouverture"}>
        <p>Lundi - Vendredi: 08:00 - 23:30</p>
        <p>Samedi - Dimanche: 08:00 - 00:30</p>
        <br />
        <ButtonPrimaryFadeIn text='Réservations' to='/contact' />
      </Welcome>
    </div>
  );
}

export default Home;
