import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../redux/cartSlice";
import style from "./CardPannier.module.css";

function CardPannier({ i, item, aos = true }) {
  const imageUrl = "https://food.powerbiz.fr/upload/";
  const dispatch = useDispatch();

  const totalPriceArr = item.addon.map((item) => item.addon_price);
  let total = 0;
  totalPriceArr.forEach((_, i) => {
    total += parseFloat(totalPriceArr[i]);
  });

  const deleteHandler = () => {
    dispatch(
      cartActions.removeFromCart({
        order_id: item.order_item_id,
        qty: item.qty,
        totalPrice: (parseFloat(item.price) + total) * item.qty,
      })
    );

    const oldProducts = JSON.parse(localStorage.getItem("cart"));
    const newProducts = _.filter(
      oldProducts,
      (product) => product.order_item_id !== item.order_item_id
    );
    localStorage.setItem("cart", JSON.stringify(newProducts));
  };
  return (
    <>
      {aos ? (
        <div
          className={`${style.card}`}
          href="single_post_image.html"
          data-aos="fade-left"
          data-aos-duration="300"
          data-aos-delay={`${400 * i}`}
        >
          <div className="post_l">
            <div className="img_p">
              <img
                src={
                  item.photo !== ""
                    ? `${imageUrl}/${item.photo}`
                    : "assets/img/logo192.png"
                }
                style={{
                  aspectRatio: "1/1",
                  height: "70px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className={style.cardData}>
              <h4 className="text_yanone" style={{ fontSize: "20px" }}>
                {item.item_name}
              </h4>
              <p className={style.textColored + " text_yanone"}>
                {((parseFloat(item.price) + total) * item.qty).toFixed(2)} DH
              </p>
            </div>
          </div>
          <button className={style.button} onClick={deleteHandler}>
            <i
              className="fa-solid fa-trash-can"
              style={{ color: "#efefef" }}
            ></i>
          </button>
        </div>
      ) : (
        <div className={`${style.card}`} href="single_post_image.html">
          <div className="post_l">
            <div className="img_p">
              <img
                src={
                  item.photo
                    ? `${imageUrl}/${item.photo}`
                    : "assets/img/logo192.png"
                }
                style={{
                  aspectRatio: "1/1",
                  height: "70px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className={style.cardData}>
              <h4 className="text_yanone" style={{ fontSize: "20px" }}>
                {item.item_name}
              </h4>
              <p className={style.textColored + " text_yanone"}>
                {((parseFloat(item.price) + total) * item.qty).toFixed(2)} DH
              </p>
            </div>
          </div>
          <button className={style.button} onClick={deleteHandler}>
            <i
              className="fa-solid fa-trash-can"
              style={{ color: "#efefef" }}
            ></i>
          </button>
        </div>
      )}
    </>
  );
}

export default CardPannier;
