import React from "react";
import ButtonPrimaryFadeInAdd from "../Buttons/ButtonPrimaryFadeInAdd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import style from "./CardProduct.module.css";
import "react-lazy-load-image-component/src/effects/blur.css";

function CardProduct({ item, openCart }) {
  const { item_name, item_description, photo, price } = item;

  function handelAddToCart() {
    openCart(item);
  }

  return (
    <div className="col-lg-4 " onClick={handelAddToCart}>
      <div className={"products_sh " + style.containerProduct}>
        <div className="image_product_add_to_cart">
          <ButtonPrimaryFadeInAdd className={style.button}>
            <i className="fas fa-shopping-basket"></i>Ajouter
          </ButtonPrimaryFadeInAdd>
          <LazyLoadImage
            src={
              photo
                ? `https://food.powerbiz.fr/upload/${photo}`
                : "assets/img/vil.jpeg"
            }
            alt={item_name}
            style={{
              aspectRatio: "16/9",
              objectFit: "cover",
            }}
            className={style.imageHover}
            effect="blur"
            // width={"100%"}
            height="100%"
          />
        </div>
        <div className="cont_product">
          <div className="row align-items-center justify-content-between">
            <span style={{ fontFamily: "none" }}>{price[0]} DH</span>
          </div>
          <h3>{item_name}</h3>
          <p>{item_description}</p>
        </div>
      </div>
    </div>
  );
}

export default CardProduct;
