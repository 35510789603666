import React from "react";

function ModalHeader({ prod, subItem, counter }) {
  const { item_name, price } = prod;

  const totalPriceArr = subItem.map((item) => item.addon_price);
  let total = 0;
  totalPriceArr.forEach((_, i) => {
    total += parseFloat(totalPriceArr[i]);
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "20px auto",
      }}
    >
      <h3 className="text_yanone">{item_name}</h3>
      <p
        className="text_yanone"
        style={{ fontSize: "18px", fontWeight: "bold" }}
      >
        {((parseFloat(price) + parseFloat(total)) * counter).toFixed(2)} DH
      </p>
    </div>
  );
}

export default ModalHeader;
