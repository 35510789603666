import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import restauSlice from "./restauSlice";

export const store = configureStore({
  reducer: {
    cartReducer: cartSlice,
    restauReducer: restauSlice,
  },
});
