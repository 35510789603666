import React from "react";
import ButtonPrimaryFadeIn from "../../components/Buttons/ButtonPrimaryFadeIn";
import CardMenu from "../../components/CardMenu/CardMenu";

function SmallMenu({ titre, subTitle, items, openCart }) {
  return (
    <div className="container">
      <div className="menu_block_two">
        <div className="menu_info_block col-lg-12">
          <span className="up_i">{subTitle}</span>
          <h2>{titre}</h2>
        </div>
        <div className="row">
          {items.map((item, i) => (
            <CardMenu index={i} item={item} key={i} openCart={openCart} />
          ))}
        </div>
        <div style={{ height: "30px" }}></div>
        <ButtonPrimaryFadeIn text="Voir toute la carte" to={"/cart"} />
      </div>
    </div>
  );
}

export default SmallMenu;
