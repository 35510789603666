import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ButtonPrimaryFadeInAdd from "../../components/Buttons/ButtonPrimaryFadeInAdd";
import ButtonPrimaryFadeOutAdd from "../../components/Buttons/ButtonPrimaryFadeOutAdd";
import { cartActions } from "../../redux/cartSlice";

function ModalFooter({
  onClose,
  counter,
  increment,
  dicrement,
  product,
  toast,
}) {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cartReducer);

  const addToCartHandler = () => {
    dispatch(cartActions.addToCart(product));
    const oldProducts = store.products;
    localStorage.setItem("cart", JSON.stringify([...oldProducts, product]));
    onClose();
    toast();
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        bottom: "10px",
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
      }}
    >
      <ButtonPrimaryFadeInAdd onClick={onClose}>Annuler</ButtonPrimaryFadeInAdd>
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          type="button"
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            border: "none",
            cursor: "pointer",
          }}
          onClick={dicrement}
        >
          <i className="fa-solid fa-minus"></i>
        </button>
        <h4
          style={{
            alignSelf: "center",
          }}
        >
          {counter}
        </h4>
        <button
          type="button"
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            border: "none",
            cursor: "pointer",
          }}
          onClick={increment}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
      <ButtonPrimaryFadeOutAdd onClick={addToCartHandler}>
        Confirmer
      </ButtonPrimaryFadeOutAdd>
    </div>
  );
}

export default ModalFooter;
