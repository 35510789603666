import React, { useRef } from "react";
import Header from "../../components/Header/Header";
import ImportScript from "../../ImportScript";
import { Helmet } from "react-helmet";
import { sendForm } from "../../services/fetchingProducts";
import ButtonPrimaryFadeOutAdd from "../../components/Buttons/ButtonPrimaryFadeOutAdd";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { useState } from "react";
import style from "./Contact.module.css";

function Contact() {
  ImportScript(
    "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js"
  );
  ImportScript("assets/js/libs.min.js");
  ImportScript("assets/js/scripts.min.js");
  ImportScript("assets/js/test.js");

  // const formHandler = (e) => {
  //   e.preventDefault();
  //   let formData = new FormData(e.target);
  //   sendForm(formData);
  // };

  const [isSending, setIsSending] = useState(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);
    emailjs
      .sendForm(
        "service_4bpqkjq",
        "template_4wghrbe",
        form.current,
        "JPe6krYVQgqPIhiYy"
      )
      .then((result) => {
        toast("Message envoyé avec succès", {
          style: {
            background: "#1f615d",
            color: "#efefef",
          },
        });
      })
      .catch((err) => {
        toast("Un erreur a été accordé", {
          style: {
            background: "#E74C3C",
            color: "#efefef",
          },
        });
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div id="contact">
      {isSending && <div className={style.overlay}></div>}
      <Helmet>
        <title>Villa Allebra - Réservations</title>
        <meta name="description" content="menu ville-allebra" />
      </Helmet>
      <Header
        item={{
          titre: "Réservations",
          description: ``,
          imageUrl: "assets/img/res.jpeg",
        }}
      />
      <section className="info_contact">
        <div className="container">
          <div className="col-lg-12">
            <div className="contact_info">
              <h2>Nous contacter</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="info_pha">
        <div className="container">
          <div className="adress_info row">
            <div className="adress_phone col-12 col-lg-6">
              <i className="icon_ph"></i>
              <svg
                className="icon_s"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <use xlinkHref="assets/img/icons/sprite.svg#icon_04_s"></use>
              </svg>
              <p></p>
              <h3>
                <a
                  href="tel:+212808561692"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  08 08 56 16 92
                </a>{" "}
              </h3>
              {/* <h3>
                <a
                  href="tel:+212630100996"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  06 30 10 09 96
                </a>{" "}
              </h3> */}
              <span>Lundi - Vendredi: 08:00 - 23:30</span>
            </div>
            <div className="adress_map col-12 col-lg-6">
              <i className="icon_ad"></i>
              <svg
                className="icon_s"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <use xlinkHref="assets/img/icons/sprite.svg#icon_05_s"></use>
              </svg>
              <p></p>
              <h3>
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href="mailto:villaallebra@gmail.com
"
                >
                  villaallebra@gmail.com
                </a>
              </h3>
              <span>Samedi - Dimanche: 08:00 - 00:30</span>
            </div>
          </div>
        </div>
      </section>
      <section className="forms">
        <div className="container">
          <form
            style={{
              marginTop: "0px",
            }}
            className="form"
            onSubmit={sendEmail}
            ref={form}
          >
            <div className="form_text">
              <p>
                Vous pouvez également nous contacter en remplissant le
                formulaire suivant{" "}
              </p>
            </div>
            <div className="form_info">
              <div className="form_reserv">
                <input
                  placeholder="Nom & Prénom"
                  type="text"
                  name="name"
                  required
                />
                <input placeholder="Email" type="email" name="email" required />
                <input
                  placeholder="Téléphone"
                  type="tel"
                  name="phone"
                  required
                />
                <input
                  placeholder="Objet"
                  type="text"
                  name="subject"
                  required
                />
              </div>
              {/* <input
                className="file"
                type="file"
                name="file"
                // accept=".docx,.doc,.pdf"
              /> */}

              <textarea
                placeholder="Message"
                type="message"
                name="message"
                required
                style={{
                  marginBottom: "20px",
                }}
              ></textarea>

              <ButtonPrimaryFadeOutAdd className="button_line" type="submit">
                Envoyer
              </ButtonPrimaryFadeOutAdd>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
