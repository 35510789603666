import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Index from "./routes/Index";
const AOS = require("aos");

// const restaurantData = getRestaurantInfo();

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    const oldProds = localStorage.getItem("cart");
    !oldProds && localStorage.setItem("cart", JSON.stringify([]));
  }, []);

  return (
    <div className="App">
      {/* <Suspense fallback={<Loading />}> */}
      <BrowserRouter>
        <Provider store={store}>
          <Index />
        </Provider>
      </BrowserRouter>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
