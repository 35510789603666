import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ButtonPrimaryFadeIn from "../Buttons/ButtonPrimaryFadeIn";
import CustomLink from "./CustomLink";
import style from "./NavBar.module.css";

function NavBar({ onShowModal }) {
  const store = useSelector((state) => state.cartReducer);
  const restauStore = useSelector((state) => state.restauReducer);
  const [isShown, setIsShown] = useState(true);
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);
  const controlNavbar = () => {
    if (window.scrollY < 300) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  function detectSize() {
    setWindowDimension(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  function toggle() {
    if (windowDimension > 991) return;
    document.querySelector(".menu").classList.toggle("menu_state_open");
    document.querySelector("body").classList.toggle("modal_open");
  }

  return (
    <>
      <div
        className={`l_m_global headroom ${isShown && style.scrolled}`}
        style={{
          background: "rgba(0,0,0, 0.8)",
          height: "100px",
        }}
      >
        <div className="container l_m" style={{ height: "100px" }}>
          <CustomLink
            to={"/"}
            className="logo"
            reload={() => window.location.reload()}
          >
            <img
              src="assets/img/logo.png"
              alt="villa-allebra"
              className="logo-villa"
            />
          </CustomLink>
          <span className="fas fa-bars" id="menu_icon"></span>
          <nav className="menu">
            <CustomLink
              to={"/"}
              reload={() => window.location.reload()}
              toggle={toggle}
            >
              Accueil
            </CustomLink>
            <CustomLink to={"/contact"} toggle={toggle}>
              Réservations
            </CustomLink>
            <CustomLink to={"/events"} toggle={toggle}>
              Divers
            </CustomLink>
            <li>
              <ButtonPrimaryFadeIn
                text="Notre carte"
                to={"/cart"}
                toggle={toggle}
                className="text_yanone"
              />
            </li>
            {/* <i className="fas fa-search search_icon"></i> */}
          </nav>
        </div>
      </div>
      {!restauStore.is_open && (
        <button
          style={{
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            background: "#1F615D",
            border: "none",
            position: "fixed",
            zIndex: "999",
            bottom: "2%",
            right: "2%",
            cursor: "pointer",
          }}
          onClick={onShowModal}
        >
          <i
            className="fas fa-shopping-cart shop_cart"
            style={{ cursor: "pointer", color: "#efefef" }}
          >
            <span
              style={{
                position: "absolute",
                top: "-5px",
                background: "#8b3c14",
                width: "20px",
                height: "20px",
                paddingTop: "2px",
                borderRadius: "50%",
              }}
            >
              {store.qty}
            </span>
          </i>
        </button>
      )}
    </>
  );
}

export default NavBar;
